<template>
  <v-app>
    <v-container>
      <!--  -->
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <!--  -->
          <v-form>
            <base-material-card
              title="Vote"
              icon="mdi-note-plus"
            >
              <!--  -->
              <v-card>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field
                        v-model="polling.data.question"
                        label="Pertanyaan"
                        placeholder="Masukan Pertanyaan"
                        clearable
                        disabled
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="(data, i) in polling.data.options"
                  :key="i"
                >
                  <!--  -->
                  <v-list-item-content
                    v-if="polling.data.settings.multiple_answer === true"
                  >
                    <v-checkbox
                      v-model="options"
                      :label="data.option"
                      :value="data.id"
                    />
                  </v-list-item-content>
                  <!--  -->
                  <v-list-item-content v-else>
                    <v-radio-group v-model="options">
                      <v-radio
                        :label="data.option"
                        :value="data.id"
                        multiple
                      />
                    </v-radio-group>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
              <div class="d-flex justify-end mt-6 mb-5">
                <v-btn
                  color="primary"
                  @click="vote"
                >
                  Vote
                </v-btn>
              </div>
            </base-material-card>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = 'https://nc.notiva.net'
  axios.defaults.headers.common.Authorization =
    'Bearer ' + localStorage.getItem('access_token')
  export default {
    name: 'VotePolling',
    data () {
      return {
        polling: {
          meta: {},
          data: {
            settings: {
              multiple_answer: false,
              anonymous_answer: false,
            },
            options: {
              id: '',
              polling_id: '',
            },
          },
        },
        options: [],
        ex: [],
      }
    },
    mounted () {
      this.getPolling()
    },
    methods: {
      vote () {
        // console.log(this.polling.data.options.voted)
        // const votes = this.polling.data.options
        //   .filter(data => data.voted === true)
        //   .map(data => data.id)
        let options = []
        if (this.polling.data.settings.multiple_answer === true) {
          options = this.options
        } else {
          options = [this.options]
        }
        console.log(options)
        axios
          .post('/v1/polling/' + this.$route.params.id + '/vote', {
            polling_option_id: options,
          })
          .then(response => {
            if (response.data.meta.status) {
              this.$router.push({
                path: '/polling/' + this.$route.params.id + '/show',
              })
              this.$toast.success('Berhasil Di Memilih', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else if (response.data.meta.message === 'Anda sudah vote') {
              this.$router.push({
                path: '/polling/' + this.$route.params.id + '/show',
              })
              this.$toast.success('Anda Sudah Vote', {
                type: 'success',
                position: 'top-right',
                duration: 3000,
                dismissible: true,
              })
            } else if (
              response.data.meta.message === 'Hanya bisa memilih 1 pilihan'
            ) {
              this.$toast.success('Hanya Bisa Memilih 1 Pilihan ', {
                type: 'success',
                position: 'top-right',
                duration: 5000,
                dismissible: true,
              })
            } else {
              console.log('Error')
            }
          })
      },
      setPolling (data) {
        this.polling = data
      },
      getPolling () {
        axios
          .get('/v1/polling/' + this.$route.params.id + '?entities=user,options')
          .then(response => {
            this.setPolling(response.data)
          })
      },
    },
  }
</script>

<style></style>
